<template>
  <b-modal v-model="modalWindow" :width="640" scroll="keep">
    <div class="card">
      <div class="card-content">
        <form @submit.prevent="submit">
          <h2>Авторизация в системе</h2>
          <b-field
            label="Сотрудник"
            :type="{
              'is-danger': $v.employee.$dirty && !$v.employee.required
            }"
            :message="{
              'Введите сотрудника': $v.employee.$dirty && !$v.employee.required
            }"
          >
            <b-select v-model="employee" placeholder="Выберите сотрудника">
              <option
                :value="employee.id"
                v-for="employee in allEmployees"
                :key="employee.id"
                >{{ employee.fullname }}</option
              >
            </b-select>
          </b-field>
          <b-field
            label="Пароль"
            :type="{
              'is-danger': $v.password.$dirty && !$v.password.required
            }"
            :message="{
              'Введите пароль': $v.password.$dirty && !$v.password.required
            }"
          >
            <b-input type="password" v-model="password"></b-input>
          </b-field>

          <div class="card-content__agreement">
            <b-checkbox v-model="agreement">
              Мен эрежелер (<a
                href="https://docs.google.com/document/d/1OTW6l48ntVKgyZwOyPZ83KmuBEAggpyR/edit?usp=sharing&ouid=104965508733758799492&rtpof=true&sd=true"
                target="_blank"
                >админдердин</a
              >,
              <a
                target="_blank"
                href="https://docs.google.com/document/d/1oKwGkdw3PfUh7e46dnAvbn2JpijJv-Xn/edit?usp=sharing&ouid=104965508733758799492&rtpof=true&sd=true"
                >мастерлердин</a
              >) менен таанышмын жана эрежелер менен иштегенге макулмун
            </b-checkbox>
          </div>

          <b-button
            native-type="submit"
            :loading="btnLoading"
            type="is-success"
            icon-left="login-variant"
            >Авторизация</b-button
          >
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import employeesApi from '@/api/employees';

export default {
  props: {},
  data() {
    return {
      employee: '',
      password: '',
      agreement: false,
      btnLoading: false,
      modalWindow: false
    };
  },
  methods: {
    ...mapMutations(['setError', 'setMessage', 'closeEmployeeCheckInModal']),
    async submit() {
      this.btnLoading = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.btnLoading = false;
        return;
      }

      if (!this.agreement) {
        this.btnLoading = false;
        return this.$error(
          'Авторизация болуш учун, эрежелер менен макул болуш керек.'
        );
      }

      const data = {
        employee: this.employee,
        password: this.password,
        shop_id: localStorage.getItem('shop_group')
      };

      employeesApi
        .employeeAuth(data)
        .then(response => {
          this.$message(response.data);
          this.btnLoading = false;
          this.password = '';
          this.agreement = false;
        })
        .catch(e => {
          this.$error(e.response.data);
          this.btnLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters(['allEmployees']),
    ...mapState(['employeeCheckInModal'])
  },
  watch: {
    employeeCheckInModal() {
      this.modalWindow = true;
      this.closeEmployeeCheckInModal();
    }
  },

  validations: {
    employee: {
      required
    },
    password: {
      required
    }
  }
};
</script>

<style lang="scss">
.select,
select {
  width: 100%;
}

.card-content {
  &__agreement {
    margin-bottom: 20px;
  }
}

.link {
  display: block;
  margin-bottom: 4px;
}
</style>
