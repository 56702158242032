var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"width":640,"scroll":"keep"},model:{value:(_vm.modalWindow),callback:function ($$v) {_vm.modalWindow=$$v},expression:"modalWindow"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('h2',[_vm._v("Авторизация в системе")]),_c('b-field',{attrs:{"label":"Сотрудник","type":{
            'is-danger': _vm.$v.employee.$dirty && !_vm.$v.employee.required
          },"message":{
            'Введите сотрудника': _vm.$v.employee.$dirty && !_vm.$v.employee.required
          }}},[_c('b-select',{attrs:{"placeholder":"Выберите сотрудника"},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}},_vm._l((_vm.allEmployees),function(employee){return _c('option',{key:employee.id,domProps:{"value":employee.id}},[_vm._v(_vm._s(employee.fullname))])}),0)],1),_c('b-field',{attrs:{"label":"Пароль","type":{
            'is-danger': _vm.$v.password.$dirty && !_vm.$v.password.required
          },"message":{
            'Введите пароль': _vm.$v.password.$dirty && !_vm.$v.password.required
          }}},[_c('b-input',{attrs:{"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"card-content__agreement"},[_c('b-checkbox',{model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_vm._v(" Мен эрежелер ("),_c('a',{attrs:{"href":"https://docs.google.com/document/d/1OTW6l48ntVKgyZwOyPZ83KmuBEAggpyR/edit?usp=sharing&ouid=104965508733758799492&rtpof=true&sd=true","target":"_blank"}},[_vm._v("админдердин")]),_vm._v(", "),_c('a',{attrs:{"target":"_blank","href":"https://docs.google.com/document/d/1oKwGkdw3PfUh7e46dnAvbn2JpijJv-Xn/edit?usp=sharing&ouid=104965508733758799492&rtpof=true&sd=true"}},[_vm._v("мастерлердин")]),_vm._v(") менен таанышмын жана эрежелер менен иштегенге макулмун ")])],1),_c('b-button',{attrs:{"native-type":"submit","loading":_vm.btnLoading,"type":"is-success","icon-left":"login-variant"}},[_vm._v("Авторизация")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }